<template>
  <div class="up-request">
    <h1 class="up-main-title">Создание заявки</h1>
    <RequestForm v-model="form" ref="form" />
    <template v-if="listeners.length > 0">
      <AppBlockAsTable class="up-request__listeners up-listeners">
        <template slot="header">
          <p class="up-listeners__fio">ФИО</p>
          <p class="up-listeners__email">Email</p>
          <p class="up-listeners__phone">Телефон</p>
          <p class="up-listeners__status">Статус</p>
          <p class="up-listeners__actions"></p>
        </template>
        <div v-for="(l, i) in listeners" :key="i" class="up-table__item">
          <p class="up-listeners__fio">
            <span class="is-pk-hidden">ФИО:</span> {{ l.name }}
          </p>
          <p class="up-listeners__email">
            <span class="is-pk-hidden">Email:</span> {{ l.email }}
          </p>
          <p class="up-listeners__phone">
            <span class="is-pk-hidden">Телефон:</span> {{ l.phone }}
          </p>
          <p class="up-listeners__status">
            <span class="is-pk-hidden">Статус:</span>
            <el-tag>Не активен</el-tag>
          </p>
          <p class="up-listeners__actions">
            <el-dropdown @command="removeListener(i)" trigger="click">
              <i class="el-icon-more" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="remove">
                  <i class="el-icon-delete" /> удалить слушателя из заявки
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </p>
        </div>
      </AppBlockAsTable>
      <el-alert class="up-request__alert" type="warning" :closable="false">
        Заявка еще не сохранена. Для продолжения сохраните заявку.
      </el-alert>
    </template>
    <el-alert v-else class="up-request__alert" type="warning" :closable="false">
      Добавьте хотя бы одного слушателя в заявку перед сохранением
    </el-alert>
    <div class="up-request__buttons">
      <el-button
        class="up-request__btn-add-listener"
        @click="addListenersModalVisible = true"
      >
        Добавить слушателя
      </el-button>
      <el-button
        type="primary"
        :disabled="listeners.length === 0"
        v-loading="loading"
        @click="openAccessModal"
      >
        Открыть всем
      </el-button>
      <el-button type="success" @click="addNewRequest">Сохранить</el-button>
    </div>
    <AddListenersModal
      :visible.sync="addListenersModalVisible"
      :added-listeners="listeners"
      @close="addListenersModalVisible = false"
      @add-listeners="addListenersHandler"
    />
    <AppSimpleModal
      :visible.sync="openAccessModalVisible"
      action-text="Подтвердить"
      title="Открыть доступ всем"
      @close="openAccessModalVisible = false"
      @action="openAccessToAll"
    >
      Вы собираетесь открыть доступ всем слушателям. Действительно хотите сделать
      это?
    </AppSimpleModal>
  </div>
</template>

<script>
import RequestForm from "@/components/manager/requests/RequestForm";
import AddListenersModal from "@/components/manager/requests/AddListenersModal";
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppSimpleModal from "@/components/AppSimpleModal";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import { mapActions } from "vuex";

export default {
  name: "NewRequest",
  mixins: [showErrorMessageMixin],
  components: {
    RequestForm,
    AddListenersModal,
    AppBlockAsTable,
    AppSimpleModal,
  },
  data: () => ({
    form: {
      customerId: null,
      dateUntil: null,
      courseId: null,
    },
    addListenersModalVisible: false,
    openAccessModalVisible: false,
    listeners: [],
    requestId: null,
    loading: false,
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    addListenersHandler(listenersArr) {
      this.listeners.push(...listenersArr);
      this.addListenersModalVisible = false;
    },
    removeListener(idx) {
      this.listeners.splice(idx, 1);
    },
    setDate() {
      const nowDate = new Date();

      this.form.dateUntil = nowDate.setMonth(nowDate.getMonth() + 3);
    },
    async openAccessToAll() {
      try {
        const { data: res } = await this.$axios.post(
          `/manager/order/${this.requestId}/createFlows`
        );

        this.listeners = res.listeners;
        this.openAccessModalVisible = false;

        this.$message({
          message: "Поздравляем! Доступ всем слушателям открыт",
          type: "success",
        });

        this.$router.push(`/manager/requests/${this.requestId}`);
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    async openAccessModal() {
      if (this.loading) return;

      this.loading = true;

      await this.addNewRequest(false);

      this.openAccessModalVisible = true;
    },
    async addNewRequest(withRedirect = true) {
      if (!(await this.$refs.form.isValid())) return;

      try {
        const { data: request } = await this.$axios.post("/manager/order", {
          listeners: this.listeners,
          courseId: this.form.courseId,
          customerId: this.form.customerId,
          dateUntil: this.form.dateUntil,
        });

        this.loading = false;
        this.requestId = request.id;

        this.$message({
          message: "Поздравляем! Заявка успешно создана.",
          type: "success",
        });

        if (withRedirect) {
          this.$router.push(`/manager/requests/${request.id}`);
        }
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
  created() {
    this.setNavigationText("Создание новой заявки");

    this.setDate();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/manager/requests/new-edit.scss";
</style>